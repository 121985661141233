// extracted by mini-css-extract-plugin
export var eventComponentContainer = "events-module--eventComponentContainer--w82GJ";
export var left = "events-module--left--AaeGe";
export var right = "events-module--right--rtXsq";
export var title = "events-module--title--kxv9O";
export var preview = "events-module--preview--mICxi";
export var location = "events-module--location--bXxC3";
export var date = "events-module--date--6CbHr";
export var eventsContainer = "events-module--eventsContainer--MkXgF";
export var header = "events-module--header--C7wJ+";
export var imageDiv = "events-module--imageDiv--r641O";